.eyecatcher {
  position: relative;
  margin-top: -10px;

  .container-default {
    @extend .px-0;

    .carousel {

      background-color: #DDDDDD;
      margin-bottom: -60px;

      .carousel-inner {
        .carousel-item {
          min-height: 480px;

          .carousel-holder {
            min-height: 480px;

            .carousel-caption {
              text-align: left;
            }
          }
          @include media-breakpoint-down(md) {
            min-height: 300px;
            .carousel-holder {
              min-height: 300px;
            }
          }
          @include media-breakpoint-down(sm) {
            min-height: 20px;
            .carousel-holder {
              min-height: 200px;
            }
          }
        }
      }
      &::before {
        content: "";
        position: absolute;
        border-top: 2px solid #fff;
        top: 0;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 999;
      }
    }
    .carousel-indicators {
      li {
        &::before { display: none; }
      }
    }
  }
  
 

  &.large {
    min-height: 70vh;

    .carousel {
      .carousel-inner {
        .carousel-item {
          min-height: 70vh;

          .carousel-holder {
            min-height: 70vh;
          }
        }
      }
    }
  }
}

.menu_overlay {
	display:none;

	&.active {
		display: block;
	}
}

.main > .section_one > .container > .container-holder {
  margin-top: -50px;
  padding-top: 30px;
  position: relative;

  padding-left: 15px;
  padding-right: 15px;
  margin-left: -15px;
  margin-right: -15px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    background: #fff;
    height: 30px;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;

    @include media-breakpoint-up(xl) {
      left: -15px;
      right: -15px;
      width: auto;
    }

  }
  
}
.main > .section_one > .container-two-columns.container > .container-holder,
.main > .section_one > .container-three-columns.container > .container-holder,
.main > .section_one > .container-four-columns.container > .container-holder,
.main > .section_one > .container-six-columns.container > .container-holder,
.main > .section_one > .container-two-columns-advanced.container > .container-holder {
  padding-left: 0;
  padding-right: 0;

}
