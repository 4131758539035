.default {
	&.program_introduction {
		.column {
			h2 {
				@extend .text-type-bold;
				margin-bottom: 15px;
				font-size: 1rem;
			}
		}
	}
}

