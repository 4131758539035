.default {
	.menu_overlay {
		.grid {
			@include make-row();

			.item {
				@include make-col-ready();
				@include make-col(12);

				.card {
					@extend .flex-row;

					.card-body {
						@extend .p-0;
						@extend .pl-4;

						@include media-breakpoint-down(sm) {
							padding: 15px 0 0!important;
						}

						.card-caption {
							@extend .d-block;
							flex: 1 1 100%;

							.card-title {
								position: relative;
								max-width: 100%;

                margin: 0 0 4px;
								padding-left: 20px;

                font-size: 1rem;
								color: $red;
								font-weight: 600;

                transition: $transition-base;

								&::before {
									position: absolute;
									top: 0;
                  left: 0;

									font-family: "Font Awesome 5 Pro";
									content: "\F061";
								}
							}

							.card-subtitle,
							.card-btn {
								display: none;
								max-width: 100%;
							}

              a {
								max-width: 100%;

                &:hover {
                  .card-title {
                    color: $dark-blue;
                  }
                }
              }
						}
					}

					@include media-breakpoint-up(xl) {
						.card-image {
							@include make-col(2);
						}

						.card-body {
							@include make-col(10);
						}
					}
				}
			}
		}

		.buttons {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-end;
			flex: 1 1 100%;

			margin-top: -10px;

			.news-btn {
				@extend .btn-next;
				margin: 10px 0 0 20px
			}
		}

	}
}

.default {
	&.blog_post_overview {
		.blog_filter {
			margin-bottom: 28px;
		} 
	}
}

.default {
	&.blog_post_overview {
		main {
			.grid {
				margin: 0;

				.item {
					padding: 0 15px;

					.card {
						@include make-row();
						@extend .border-0;
						flex-direction: row;
						height: inherit!important;

						@include media-breakpoint-down(sm) {
							flex-direction: column;
						}

						.card-image {
							@include make-col(2);
							img {
								border-radius: 0;
							}
							@include media-breakpoint-down(md) {
								@include make-col(3);
							}
							@include media-breakpoint-down(sm) {
								@include make-col(12);
							}
						}

						.card-body {
							@include make-col(10);
							@extend .p-0;
							@extend .pl-4;

							@include media-breakpoint-down(md) {
								@include make-col(9);
							}
							@include media-breakpoint-down(sm) {
								@include make-col(12);
								padding: 15px 0 0!important;
							}

							.card-caption {
								@extend .d-block;

								.card-subtitle {
									display: flex!important;
									font-size: 0.875rem;
									margin: 0 0 15px;
									@extend .text-type-bold;
								}
								.card-btn {
									display: none;
								}

								.card-title {
									position: relative;
									padding-left: 20px;
									margin: 0 0 4px;
									color: $red;
									font-size: 1rem;
									line-height: 1rem;
									font-weight: 600;

									&::before {
										position: absolute;
										top: 0;
										left: 0;
										font-family: "Font Awesome 5 Pro";
										content: "\F061";
									}
									&:hover {
										color: $dark-blue;
									}
								}
							}
						}


					}
				}
			}
		}
	}
}


