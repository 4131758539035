.carousel {
  .carousel-inner {
    .carousel-item-bg {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      .carousel-holder {
        @extend .d-flex;
        @extend .align-items-center;

        .carousel-caption {
          position: relative;
          right: unset;
          bottom: unset;
          left: unset;
        }
      }
    }
    
  }
}
