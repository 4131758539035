.publications {
    ul {
        padding: 0;
        list-style: none;
        .publication-year {
            margin: 15px 0;
            .year {
                font-weight: 600;
                color: $dark-blue;
            }
            .publication-item {
                margin: 0 0 30px;
            }
        }
    }
}