.header {
  position: relative;
  padding: 35px 0;
  transition: all 0.1s ease;
  z-index: 100;
  &.open-menu {
    background: $body-bg;
  }

  @include media-breakpoint-down(md) {
    padding: 15px 0;
  }

  .container-holder {

    @extend .align-items-center;

  }
    
    .header-main {
      .column.one {

        margin-bottom: 0!important;

        @include media-breakpoint-down(md) {
          @include make-col(6);
        }

        .logo {
        max-width: 190px;
        transition: $transition-base;

          a {
            @extend .d-block;

            img {
              @extend .w-100;
            }
          }
        }

      }

      .column.two {
        @extend .d-flex;
        @extend .justify-content-end;
        @extend .align-items-center;

        @include media-breakpoint-down(md) {
          @include make-col(6);
        }

        .search-block {
          @extend .pr-3;
          @extend .mr-3;
          @extend .d-flex;
          @extend .align-items-center;
          height: 50px;
          border-right: 1px solid #001031;

          .form-inline {
            @extend .mr-2;
            display: none;
            z-index: 9999;
            

            .form-control {
              border-color: #1a1e3e;
              border-radius: 0;
              box-shadow: none;

              @include media-breakpoint-down(md) {
                max-width: 150px;
              }
            }

            @include media-breakpoint-down(sm) {
              position: absolute;
              top: 45px;
              left: 0px;
            }
          }
        }
      }
    }
  }
 

// aparte onderdelen van maken
.menu_overlay {

  position: relative;
  background: $white;

  @include media-breakpoint-up(md) {
    position: absolute;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
    overflow: hidden;
  }
  &::after {
    content: '';
    position: absolute;
    height: 100px;
    width: 100%;
    bottom: 0;
    background: $dark-blue;
    z-index: 9;
  }

  .container-two-columns {

    margin-top: 80px;
    margin-bottom: 160px;
    
    @include media-breakpoint-down(md) {
      margin-top: 0;
      margin-bottom: 50px;
    }


    .container-holder {
      .column.one {

        @include make-col(5);
        @include media-breakpoint-down(md) {
          @include make-col(12);
        }

      }

      .column.two {
        @extend .d-block;

        @include make-col(7);
        @include media-breakpoint-down(md) {
          @include make-col(12);
          margin: 0!important;
        }

        h1 {
          @extend .text-type-blue-cap;
          @extend .mb-2;
        }

        // components > news
        @extend .grid;
      }
    }
    -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 1.5s; /* Firefox < 16 */
			-ms-animation: fadein 1.5s; /* Internet Explorer */
			-o-animation: fadein 1.5s; /* Opera < 12.1 */
        animation: fadein 1.5s;
    
  }
  .container-default {
    background: $dark-blue;
    height: 100px;
    z-index: 10;
    position: relative;
    @extend .d-flex;
    @extend .align-items-center;

      .container-holder {
         // Dit moet ook een aprt onderdeel worden
         @extend .d-flex;
         @extend .align-items-center;
         
         .image {
           height: 32px;
           @extend .d-block;
           padding-right: 15px;
           
           img {
             height: 100%;
             width: auto;
           }
           &:first-child {
             border-right: 1px solid #fff;
             margin-right: 15px;
           }
         }
         @include media-breakpoint-down(xs) {
           flex-wrap: wrap;
           justify-content: center;
           a {
             padding: 0 15px;
             margin: 5px 0;
             &:first-child {
               border: none;
               margin: 0;
             }
           }
         }
         // ---- tot hier
      }
      -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
		  -moz-animation: fadein 1.5s; /* Firefox < 16 */
			-ms-animation: fadein 1.5s; /* Internet Explorer */
			-o-animation: fadein 1.5s; /* Opera < 12.1 */
        animation: fadein 1.5s;
  }
  
        
        @keyframes fadein {
          from { opacity: 0; }
          to   { opacity: 1; }
        }
        
        /* Firefox < 16 */
        @-moz-keyframes fadein {
          from { opacity: 0; }
          to   { opacity: 1; }
        }
        
        /* Safari, Chrome and Opera > 12.1 */
        @-webkit-keyframes fadein {
          from { opacity: 0; }
          to   { opacity: 1; }
        }
        
        /* Internet Explorer */
        @-ms-keyframes fadein {
          from { opacity: 0; }
          to   { opacity: 1; }
        }
}

// NOG IDELEN!!!!!

/*  ==========================================================================
    menu
    ========================================================================== */

/*  menu > nav-toggle
    ========================================================================== */
    .nav-toggle {
      z-index: 100;
      opacity: 1;

      font-size: 17px;
      font-weight: 700;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
      color: $dark-blue !important;
      
      pointer-events: all;
      cursor: pointer;

      transition: $transition-fade;

      &:hover {
        opacity: 0.5;
      }
    }
  
    /* Hamburger
       ========================================================================== */
    .hamburger {
      padding: 1px 0 0 5px;
      cursor: pointer;
      transition-property: opacity,filter;
      transition-duration: .15s;
      transition-timing-function: linear;
      font: inherit;
      color: inherit;
      text-transform: none;
      background-color: transparent;
      border: 0;
      margin: 0;
      overflow: visible;
      float: right; 
    }
    .hamburger:hover {
      opacity: 0.7; 
    }
    .hamburger-box {
      width: 20px;
      height: 12px;
      display: inline-block;
      position: relative; 
    }
    .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: -2px; 
    }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 15px;
      height: 2px;
      background-color: #001031;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; 
    }
    .hamburger-inner {
      width: 18px;
    }
    .hamburger--spin.is-active .hamburger-inner {
      width: 15px;
    }
    .nav-toggle:hover .hamburger-inner, 
    .nav-toggle:hover .hamburger-inner::before, 
    .nav-toggle:hover .hamburger-inner::after {
      background-color: #000;
    }
    .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block; 
    }
    .hamburger-inner::before {
      top: -5px; 
    }
    .hamburger-inner::after {
      bottom: -5px; 
    }
    /* Spin */
    .hamburger--spin .hamburger-inner {
      transition-duration: 0.22s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); 
    }
    .hamburger--spin .hamburger-inner::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; 
    }
    .hamburger--spin .hamburger-inner::after {
      transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
    }
    .hamburger--spin.is-active .hamburger-inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); 
    }
    .hamburger--spin.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; 
    }
    .hamburger--spin.is-active .hamburger-inner::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); 
    }

  /* 	Search
      ========================================================================== */
    
    .search-block {
      .search-icon {
        color: $dark-blue;
        cursor: pointer;

        transition: $transition-base;

        &:hover {
          color: $red !important;
        }
      }
    }
  
  /* 	Mega-menu
       ========================================================================== */
    .menu-block {
      height: 100%;
      margin-bottom: 30px;
      @include media-breakpoint-down(sm) {
        padding: 35px 0;
        margin: 0;
        font-size: 0.875rem;
      }
    }
    .menu-block .navbar-nav {
      position: relative;
      height: 100%;
      margin: 0;
      padding: 15px 0;
      list-style: none;
      border-right: 1px solid #001031;
      transition: $transition-base;
      transition-delay: 0.3s;
      @include media-breakpoint-down(sm) {
        border: none;
      }
    }
    .menu-block .navbar-nav::before {
      content: '';
      position: absolute;
      top: 0;
      left: 40%;
      height: 100%;
      bottom: 0;
      border-right: 1px solid #001031;
      @include media-breakpoint-down(sm) {
        left: 50%;
      }
    }

    .menu-block .navbar-nav > li {
      width: 40%;
      font-weight: 700;
      position: static;

      @include media-breakpoint-down(sm) {
        width: 50%;
      }

    }
    .menu-block .navbar-nav  li  a {
      position: relative;
      font-size: 16px;
      line-height: 1rem;
      padding: 0.5rem 0;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;
      -webkit-transition: color 0.3s, background 0.3s;
      transition: color 0.3s, background 0.3s;
      white-space: normal;
      outline: none!important;
      @include media-breakpoint-down(xs) {
        font-size: 12px;
      }
    }
    .menu-block .navbar-nav .dropdown-toggle::after {
      display: none;
    }
    .menu-block .navbar-nav > li > a {
      display: inline-block;
      color: #001031;
     
      z-index: 9999;
      cursor: pointer;
    }
    .menu-block .navbar-nav > li.active > a {
      font-weight: 600;
    }
    .menu-block .navbar-nav > li > a:hover,
    .menu-block .navbar-nav > li.active > a,
    .menu-block .navbar-nav > li.show > a {
      color: #cf003d;
    }
    .menu-block .navbar-nav > li.active > a::before,
    .menu-block .navbar-nav > li.show > a::before {
      content:"";
      border-bottom: 2px solid #cf003d;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    .menu-block .navbar-nav li .arrow { display: none; }
  
    /* menu > ul */
    .menu-block .navbar-nav li > .dropdown-menu {
      position: absolute;
      visibility: hidden;
      right: 0;
      top: -100%!important;
      left: 0;
      height: 100%;
      margin-left: 40%;
      margin-top: 15px;
      margin-bottom: 15px;
      z-index: 100;
      opacity: 0;
      transition: $transition-base !important;
      list-style: none;
      padding: 0;

      // reset
      transform: none!important;
      will-change: unset!important;
      border: none;
      border-radius: unset;
      background: none;
      display: block;

      @include media-breakpoint-down(sm) {
        margin-left: 50%;
      }

    }
    .menu-block .navbar-nav li > .dropdown-menu.show {
      visibility: visible;
      opacity: 1;
      top: 0!important;
    }

    .menu-block .navbar-nav li > .dropdown-menu .dropdown-item,
    .menu-block .navbar-nav li>.dropdown-menu .nav-link {
      display: block;
      color: #cf003d;
      padding-left: 30px;
    }
  

    .menu-block .navbar-nav li > .dropdown-menu .dropdown-item:hover,
    .menu-block .navbar-nav li > .dropdown-menu .dropdown-item.active {
      color: darken($color: $red, $amount: 10);
      background: none;
    }
  

  