.gallery {
  .gallery-holder {
    @include make-row();

    .highlight {
      @include make-col-ready();
      @include make-col(12);
    }

    .item {
      @include make-col-ready();
      @include make-col(6);

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      @extend .mt-4;
    }
  }
}

// Fancybox
.fancybox-container {
  .fancybox-inner {
    .fancybox-toolbar {
      .fancybox-button--zoom {
        @extend .d-none;
      }
    }
  }
}