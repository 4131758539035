.contentblock.grid {
	@include make-row();

	.item {
		@include make-col-ready();
		@include make-col(12);

		@include media-breakpoint-up(md) {
			@include make-col(6);
		}
		@include media-breakpoint-up(lg) {
			@include make-col(3);
		}

		@extend .mb-3;
	}
}

.contentblock.slider {}

.contentblock.inspiration .item {
	@include make-col-ready();
	@include make-col(12);

	@include media-breakpoint-up(sm) {
		@include make-col(4);
	}
	@include media-breakpoint-up(xl) {
		@include make-col(4);
	}

	@extend .mb-3;

	.card-subtitle {
		@extend .small;
	}
	.card-text {
		@extend .mt-3;
	}
}
