.footer {
  .footer-top {
    background: $theme-bgcolor-quaternary;
    @extend .text-type-footer;
    @extend .py-5;
    @extend .px-3;
    @extend .px-sm-0;

    @include media-breakpoint-down(sm) {
      padding-left: 0!important;
      padding-right: 0!important;
    }

    h5 {
      @extend .text-type-footer-title;
    }

    .footer_list {
      .list {
        .list-item {
          a {
            position: relative;
            padding-left: 20px;

            font-weight: normal;
            color: $dark-blue;
            transition: $transition-base;
            display: block;

            &::before {
                position: absolute;
                top: -2px;
                left: 0;
        
                font-family: "Font Awesome 5 Pro";
                color: $white;
                
                content: "\f061";
                transition: $transition-base;
            }

            &:hover {
              color: $red;

              &::before {
                color: $red;
              }
            }
          }
        }
      }
    }

    .column {
      &.one {
        @include media-breakpoint-up(md) {
          @include make-col(4);
        }
      }

      &.two {
        @include media-breakpoint-up(md) {
          @include make-col(5);
        }
      }

      &.three {
        @include media-breakpoint-up(md) {
          @include make-col(3);
        }

      }

    }
  }

  .footer-bottom {
    background: $theme-color-secondary;
    height: 100px;
    @extend .d-flex;
    @extend .align-items-center;

    @include media-breakpoint-down(xs) {
      height: auto;
      padding: 15px 0;
    }

    .column {
      &.one {
        // Dit moet ook een aprt onderdeel worden
        @extend .d-flex;
        @extend .align-items-center;
        
        .image {
          @extend .d-block;
          height: 32px;
          padding-right: 15px;
          
          img {
            height: 100%;
            width: auto;

            opacity: 1;
            transition: $transition-fade;
          }

          &:first-child {
            border-right: 1px solid #fff;
            margin-right: 15px;
          }

          &:hover {
            img {
              opacity: 0.75;
            }
          }
          @include media-breakpoint-down(md) {
            img {
              height:auto;
              width: 100%;
            }
          }
        }
        

        @include media-breakpoint-down(xs) {
          flex-wrap: wrap;
          justify-content: center;

          a {
            padding: 0 15px;
            margin: 15px 0;

            &:first-child {
              border: none;
            }
            img {
              height: 100%;
              width: auto;
            }
          }
         
        }
        // ---- tot hier

      }

      &.two {
        @extend .d-flex;
        @extend .justify-content-end;
        @extend .align-items-center;


        .footer_list {
          .list {
            @extend .d-flex;

            .list-item {
              padding-left: 5px;
              margin-left:  5px;
              border-left: 1px solid #fff;
              
              font-size: 0.875rem;
              line-height: 12px;

              a {
                padding-left: 0;

                font-weight: normal;
                color: $white;
                transition: $transition-base;

                &::before {
                  display: none;
                }

                &:hover {
                  color: $red;
                }
              }

              &:first-child {
                border: none;
              }
            }
          }
        }
        @include media-breakpoint-down(xs) {
          justify-content: center!important;
        }
      }

    }

    .container-navbar {
      @extend .px-0;

      .container-holder {
        .column.navbar {
          @extend .p-0;

          .footer_link,
          .footer_logolink {
            .list {
              @extend .d-flex;
              @extend .flex-wrap;

              .list-item {
                @extend .mx-2;
              }
            }
          }
        }
      }
    }
  }
}
