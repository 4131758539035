.list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        a {
            position: relative;
            padding-left: 20px;
            margin: 0 0 4px;
            font-weight: 600;
            &::before {
                position: absolute;
                top: -2px;
                left: 0;
                font-family: "Font Awesome 5 Pro";
                color: $red;
                content: "\F061";  
                font-weight: 300;
            }
        }
    }
}

.container-holder ol,
.research_division_overview ul,
.programs ul
 {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        position: relative;
        padding-left: 20px;
        margin: 0 0 4px;
        a {
            font-weight: 600;
        }
        &::before {
            position: absolute;
            top: -2px;
            left: 0;
            font-family: "Font Awesome 5 Pro";
            color: $red;
            content: "\F061"; 
            font-weight: 300; 
        }
    }
}

