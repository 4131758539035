.container {
	@include media-breakpoint-up(xl) {
		max-width: 1170px;
	}
}
.container-navbar {
	.container-holder {
		.column.default {

		}
		.column.navbar {
			@extend .flex-wrap;
		}
	}
}

.container-default {
	.container-holder {

	}
}

.container-one-column {
	.container-holder {
		@include make_row();

		.column_default {
			@include make-col-ready();
			@include make-col(12);
		}
	}
}

.container-two-columns {
	.container-holder {
		@include make-row();

		.column.one,
		.column.two {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(md) {
				@include make-col(6);
			}
		}
		.column.one {
			@extend .mb-3;
			@extend .mb-md-0;
		}
	}
}

.container-three-columns {
	.container-holder {
		@include make-row();

		.column.one,
		.column.two,
		.column.three {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(md) {
				@include make-col(4);
			}
		}
		.column.one,
		.column.two {
			@extend .mb-3;
			@extend .mb-md-0;
		}
	}
}

.container-four-columns {
	.container-holder {
		@include make-row();

		.column.one,
		.column.two,
		.column.three,
		.column.four {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(3);
			}
		}
		.column.one,
		.column.two,
		.column.three {
			@extend .mb-3;
			@extend .mb-lg-0;
		}
		.column.three {
			@extend .mb-sm-0;
		}
	}
}

.container-six-columns {
	.container-holder {
		@include make-row();

		.column.one,
		.column.two,
		.column.three,
		.column.four,
		.column.five,
		.column.six {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			@include media-breakpoint-up(md) {
				@include make-col(4);
			}

			@include media-breakpoint-up(xl) {
				@include make-col(2);
			}
		}
		.column.one,
		.column.two,
		.column.three,
		.column.four,
		.column.five {
			@extend .mb-3;
			@extend .mb-xl-0;
		}
		.column.four,
		.column.five {
			@extend .mb-md-0;
		}
		.column.five {
			@extend .mb-sm-0;
		}
	}
}
.container-two-columns-advanced {
	.container-holder {
		@include make-row();

		.column.one,
		.column.two {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}
		}
		.column.one {
			@extend .mb-3;
			@extend .mb-md-0;
		}
		.column.two {
			@include make-row();
			align-content: flex-start;
			margin: 0!important;

			.column.one,
			.column.two {
				@include make-col-ready();
				@include make-col(12);
				padding-top: 15px;
	
				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}
				img {
					width: 100%;
					height: auto;
				}
			}
			
			.column.one {
				padding-left: 0;
			}
			.column.two {
				padding-right: 0;
				@include media-breakpoint-down(md) {
					padding: 0;
				}
			}

			@include media-breakpoint-down(md) {
				margin: 0;
			}
			
		}
	}
}
main {
	.container-two-columns,
	.container-two-columns-advanced
	{
		.container-holder {
			.column.two {
				@include media-breakpoint-up(md) {
					padding-top: 58px;
				}
			}
		}
	}
}