.default {
  &.search {
    .section_one {
      h1 {
        @extend .text-type-subtitle;
        @extend .mb-2;
      }

      h2 {
        @extend .text-type-regular-blue;
        @extend .mb-4;
      }
      .alert {
        margin: 15px;
      }
      .grid {
        @extend .flex-column;
        .search_term {
          padding: 15px;
        }
        .search_results {
          .item {
            .card {
              .card-body {
                padding: 15px;
              }
            }
          }
        }
      }
    }
  }
}