.card {
    @extend .h-100;
    @extend .border-0;
    @extend .rounded-0;

    .card-image {
        img {
          @extend .rounded-0;
        }

        a {
            display: block;
            opacity: 1;
            transition: $transition-fade;

            &:hover {
              opacity: 0.75;
            }
        }
    }

    .card-img-overlay {
        background-color: rgba(108, 117, 125, 0.8);

        .card-caption {
            @extend .text-white;

            .card-btn {
                @extend .btn;
                @extend .btn-outline-primary;
            }
        }
    }

    .card-body {
        @extend .d-flex;

        .card-caption {
            @extend .d-flex;
            @extend .flex-column;
            @extend .align-items-start;
            flex: 1 1 auto;

            .card-btn {
                @extend .btn;
                @extend .btn-primary;
                @extend .mt-auto;
            }
        }
    }

    &.wide {
        @extend .flex-row;
        @extend .flex-wrap;

        .card-image {
            @include make-col-ready();
            @include make-col(12);

            @include media-breakpoint-up(sm) {
				@include make-col(4);
            }
            
            @extend .p-0;
        }

        .card-body {
            @include make-col-ready();
            @include make-col(12);

            @include media-breakpoint-up(sm) {
				@include make-col(8);
            }
        }

        &.card-arrangement {
            .card-body {
                @extend .flex-wrap;

                .card-caption {
                    @include make-col-ready();
                    @include make-col(12);

                    @include media-breakpoint-up(md) {
                        @include make-col(8);
                    }
                }

                .card-info {
                    @include make-col-ready();
                    @include make-col(12);

                    @include media-breakpoint-up(md) {
                        @include make-col(4);
                    }

                    @extend .d-flex;
                    @extend .flex-column;

                    .card-prices {
                        @extend .mb-3;

                        .card-price-label {
                            @extend .font-weight-bold;
                        }

                        .card-price {
                            font-size: $font-size-lg;
                            @extend .font-weight-bold;
                        }
                    }

                    .card-traveldata {
                        font-size: $font-size-sm;

                        .card-arrival-label {
                            @extend .font-weight-bold;
                        }
                    }
                }

                .card-spacer {
                    @include make-col-ready();
                    @include make-col(12);
                }

                .card-properties {
                    @include make-col-ready();
                    @include make-col(12);

                    @include media-breakpoint-up(md) {
                        @include make-col(8);
                    }
                }

                .card-actions {
                    @include make-col-ready();
                    @include make-col(12);

                    @include media-breakpoint-up(md) {
                        @include make-col(4);
                    }

                    .card-btn {
                        @extend .btn;
                        @extend .btn-block;
                        @extend .btn-primary;
                    }
                }
            }
        }
    }
}


// Extensions
.employee_overview,
.research_program_people_overview {
    .card {
        .card-image {
            flex: 0 0 100%;
            @extend .mb-3;
        }

        .card-body {
            flex: 0 0 100%;
            @extend .p-0;

            .card-caption {
                .card-title {
                    @extend .text-type-regular-blue;
                    transition: $transition-base;
                    font-weight: 600;
                }

                a {
                    @extend .d-flex;
                    &:hover {
                        .card-title {
                            color: $red;
                        }
                    }
                    &::after {
                        font-family: "Font Awesome 5 Pro";
                        content: "\F061";
                        margin-left: 10px;
                        margin-top: -2px;
                    }
                }

                .card-text {
                    @extend .text-type-light-gray;
                    color: $dark-blue;
                    font-size: 0.875rem;
                }

                .card-btn {
                    @extend .d-none;
                    @extend .btn-arrow;
                    @extend .ml-auto;
                }
            }
        }
    }
}

.employee_student_overview,
.research_program_people_student_overview {
    .card {
        .card-body {
            @include make-col-ready();
            @include make-col(12);

            @include media-breakpoint-up(sm) {
				@include make-col(12);
            }

            @extend .p-0;

            .card-caption {
                .card-title {
                    @extend .text-type-regular-blue;
                    font-weight: 600;
                }
            }
        }
    }
}