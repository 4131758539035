// Variables
/*

Division
Blood = #e9242d
Heart = #912e7a
Vessels = #00afec

Colors
Rood = #cf003d
Donkerblauw = #001031
Grijs = #4a4a49

Buttons


*/

// Variables
//
// Color system

$red: 							#cf003d;
$dark-blue: 				#001031;
$gray: 							#4a4a49;
$light-gray: 				#d6d7dc;
$white: 						#ffffff;

$red-blood:						#e9242d;
$purple-heart:					#912e7a;
$blue-vessels:					#00afec;

$theme-colors: (
	"primary": 					$red,
	"secondary": 				$dark-blue,
	"tertiary": 				$gray,
  "quaternary": 			$light-gray
);


// Body
//
// Settings for the `<body>` element.

$body-bg:						#fff !default;
$body-bg-home:				$light-gray !default;
$body-color: 					$gray;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-primary: 			'Montserrat', sans-serif;
$font-family-base:				$font-family-primary;
$font-size-base:				1rem !default; // Assumes the browser default, typically `16px`

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         	.5rem !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight: 				500 !default;
$btn-border-radius: 			0 !default;

// Forms
$input-border-radius: 			0;



// theme styling
$theme-color-primary: 			map_get($theme-colors, 'primary');
$theme-color-primary-dark: 		darken($theme-color-primary, 10%);

$theme-color-secondary: 		map_get($theme-colors, 'secondary');
$theme-color-secondary-dark: 	darken($theme-color-secondary, 10%);

$theme-bgcolor-tertiary: 		map_get($theme-colors, 'tertiary');
$theme-color-secondary-dark: 	darken($theme-bgcolor-tertiary, 10%);

$theme-bgcolor-quaternary: 		map_get($theme-colors, 'quaternary');
$theme-color-quaternary-dark: 	darken($theme-bgcolor-quaternary, 10%);


// links
$link-hover-decoration:         none !default;

