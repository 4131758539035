.filter {
    .dropdown-toggle {
        position: relative;

        width: 100%;
        padding: 10px 15px;
        padding-right: 55px;

        background-color: $light-gray;
        @extend .border-0;
    
        line-height: 20px;
        text-align: left;
        color: $dark-blue;
        font-weight: 600;
        
        text-overflow: ellipsis;
        overflow: hidden;

        transition: $transition-base;

        &::after {
            position: absolute;
            top: 0;
            right: 0;

            width: 40px;
            @extend .m-0;
            @extend .border-0;

            font-family: "Font Awesome 5 Pro";
            font-size: 1rem;
            font-weight: 300;
            line-height: 40px;
            text-align: center;
    
            content: "\f063";
            transition: $transition-base;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            color: $red;
        }
    }

    .dropdown-menu {
        width: 100%;
        @extend .m-0;

        background-color: $light-gray;
        @extend .border-0;
        @extend .rounded-0;

        & > .dropdown-item {
            position: relative;
            padding: 2.5px 15px;
            padding-left: 40px;
            color: $dark-blue;

            white-space: normal;

            & > i {
                display: none;
            }

            &::before {
                position: absolute;
                left: 15px;
    
                font-family: "Font Awesome 5 Pro";
                font-size: 20px;
                font-weight: 300;
                line-height: 20px;
                text-align: center;
                color: $white;
        
                content: "\f061";
            }

            &:focus {
                @extend .bg-transparent;
                outline: none;
            }

            &:hover {
                @extend .bg-transparent;
                color: $red;

                &::before {
                    color: $red;
                }
            }
        }
    }

    &.show {
        .dropdown-toggle {
            &::after {
                transform: rotate(-180deg);
            }
        }
    }
}