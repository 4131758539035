.default {
  &.employee_employee_overview,
  &.program_people {
    .section_one {
      .column.one {
        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }
        @include media-breakpoint-up(xl) {
            @include make-col(9);
        }

        @extend .mb-3;
      }

      .column.two {
        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
        @include media-breakpoint-up(xl) {
            @include make-col(3);
        }

        @extend .mb-3;
        @extend .pt-0;
      }
    }

    .section_two {
      overflow: hidden;

      .heading {
        position: relative;

        @extend .mt-5;
        @extend .py-3;

        .heading-title {
          @extend .mb-0;

          font-size: 1.25rem;
          line-height: 1.5rem;
          color: $red;
        }

        &::after {
          position: absolute;
          right: -100%;
          bottom: 0;
          left: -100%;

          border-top: 2px solid #7f8797;
          content: '';
        }

        & ~ .heading {
          @extend .mt-1;
        }
      }

      .employee_overview,
      .research_program_people_overview {
        .item {
          @include make-col-ready();
          @include make-col(12);

          @include media-breakpoint-up(md) {
            @include make-col(6);
          }
          @include media-breakpoint-up(lg) {
            @include make-col(4);
          }
          @include media-breakpoint-up(xl) {
            @include make-col(3);
          }

          @extend .mb-3;
          @extend .mb-sm-4;
        }

        .card {
          @extend .flex-row;
          @extend .flex-wrap;
        }
      }

      .employee_student_overview,
      .research_program_people_student_overview {
        @extend .py-3;

        .item {
          @include make-col-ready();
          @include make-col(12);

          @include media-breakpoint-up(md) {
            @include make-col(6);
          }
          @include media-breakpoint-up(lg) {
            @include make-col(4);
          }
          @include media-breakpoint-up(xl) {
            @include make-col(3);
          }

          @extend .mb-1;
        }

        .card {
          @extend .flex-row;
          @extend .flex-wrap;
        }
      }
    }
  }

  &.employee_employee_detail,
  &.program_people_detail {
    .section_one {
      .employee_detail {
        @include make-row();

        .content {
          @include make-col-ready();
          @include make-col(12);

          @include media-breakpoint-up(md) {
            @include make-col(6);
          }

          @extend .mb-4;
          @extend .text-type-light-gray;

          h1 {
            @extend .mb-2;
            @extend .text-type-subtitle;
          }

          h2 {
            @extend .mb-4;
            @extend .text-type-regular-blue;
          }
        }

        .info {
          @include make-col-ready();
          @include make-col(12);

          @include media-breakpoint-up(md) {
            @include make-col(6);
          }

          @extend .mb-4;
          @extend .text-type-light-gray;

          .gallery {
            @extend .mb-4;
          }

          .social {
            @extend .d-flex;
            @extend .flex-wrap;
            @extend .m-0;
            @extend .p-0;

            @extend .list-unstyled;

            & > li {
              & > a {
                @extend .d-block;
                width: 35px;
                height: 35px;
                margin-right: 10px;
                background-size: 35px;
                transition: background 0.5s ease;

                &.email { background-image: url('~project/public/images/mail.png');  }
                &.linkedin { background-image: url('~project/public/images/linkedin.png');  }
                &.google { background-image: url('~project/public/images/google.png');  }
                &.rg { background-image: url('~project/public/images/rg.png');  }
                &.twitter { background-image: url('~project/public/images/twitter.png');  }

                &.email:hover { background-image: url('~project/public/images/mail-on.png');  }
                &.linkedin:hover { background-image: url('~project/public/images/linkedin-on.png');  }
                &.google:hover { background-image: url('~project/public/images/google-on.png');  }
                &.rg:hover { background-image: url('~project/public/images/rg-on.png');  }
                &.twitter:hover { background-image: url('~project/public/images/twitter-on.png');  }
              }
            }
          }
        }
        .publications {
          @include make-col-ready();
          @include make-col(12);
        }

        .extra {
          @include make-col-ready();
          @include make-col(12);

          .back-to-overview {
            a {
              @extend .btn-back;
            }
          }
        }
      }
    }
  }
}
