.default {
  &.division_detail {
    .section_one {
      .research_division_detail {
        @include make-row();

        .left {
          @include make-col-ready();
          @include make-col(12);

          @include media-breakpoint-up(md) {
              @include make-col(6);
          }
          
          @extend .mb-4;
          @extend .mb-md-0;
          @extend .text-type-light-gray;

          h2 {
            @extend .mb-2;
            @extend .text-type-subtitle;
          }
        }

        .right {
          @include make-col-ready();
          @include make-col(12);

          @include media-breakpoint-up(md) {
              @include make-col(6);
          }

          @extend .mb-4;
          @extend .mb-md-0;

          .gallery {
              @extend .mb-4;
          }
        }
      }
    }
  }
}