.default {
	&.assortiment_overview {
		.section_one {
			@extend .px-3;
			@extend .py-3;

			@extend .px-sm-5;
			@extend .py-sm-5;

			.assortiment_overview {
				.item {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(md) {
						@include make-col(6);
					}
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}
					@include media-breakpoint-up(xl) {
						@include make-col(3);
					}

					@extend .mb-3;
					@extend .mb-sm-4;
				}
			}
		}
	}

	&.assortiment_detail {
		.section_one {
			@extend .px-3;
			@extend .py-5;

			@extend .px-sm-5;
			
			.assortiment_detail {
				@include make-row();
				@extend .pb-5;

				.info {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@extend .mb-4;
					@extend .pr-lg-5;
				}

				.content {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@extend .mb-4;
					@extend .pl-lg-5;
				}
			}
		}
	}
}
