.btn-arrow {
    @extend .bg-transparent;
    @extend .border-0;

    color: $red;
    transition: $btn-transition;

    &::after {
        font-family: "Font Awesome 5 Pro";
        content: "\f061";
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        color: $dark-blue;
    }
}

.btn-back {
    @extend .p-0;
    @extend .bg-transparent;
    @extend .border-0;

    font-size: 0.875rem;
    line-height: 40px;
    color: $dark-blue;

    transition: $btn-transition;

    &::before {
        display: inline-block;
        width: 40px;
        margin-right: 10px;

        border: 1px solid $dark-blue;

        font-family: "Font Awesome 5 Pro";
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 38px;
        text-align: center;

        content: "\f053";
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        color: $red;

        &::before {
            border-color: $red;
        }
    }
}

.btn-next {
    @extend .p-0;
    @extend .bg-transparent;
    @extend .border-0;

    font-size: 0.875rem;
    line-height: 40px;
    color: $dark-blue;

    transition: $btn-transition;

    &::after {
        display: inline-block;
        width: 40px;
        margin-left: 10px;

        border: 1px solid $dark-blue;

        font-family: "Font Awesome 5 Pro";
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 38px;
        text-align: center;

        content: "\f054";
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        color: $red;

        &::after {
            border-color: $red;
        }
    }
}


.back-to-home {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 1000;

    display: block;
    width: 50px;
    height: 150px;

    background: transparent url('~project/public/images/label-divisions.png') 0 0 no-repeat;
    background-size: contain;

    transition: $btn-transition;

    &:hover {
        opacity: 0.9;
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }

}

.scroll-up {
    z-index: 998;
    position: fixed;
    bottom: -65px;
    right: 25px;
    width: 40px;
    border: 1px solid $white;
    background-color: $dark-blue;
    box-shadow: 3px 3px 5px 0 rgba(0,0,0,.1);
    color: $white!important;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    transition: .5s;
    cursor: pointer;
}
