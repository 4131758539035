.default {
	&.blog_post_overview {
		.section_one {

			.blog_overview {
				@extend .grid;

				.item {
					@include make-col-ready();
					@include make-col(12);

					@extend .mb-4;
				}
			}
		}
	}

	&.blog_post_detail {
		.section_one {

			.blog_detail {
				@include make-row();
				@extend .pb-5;

				.intro {
					@include make-col-ready();
					@include make-col(12);
					h1 {
						@extend .mb-3;
					}
				}

				.info {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(md) {
						@include make-col(9);
					}

					@extend .mb-4;
					@extend .pr-lg-5;
					.back-to-overview {
						@extend .mt-3;
						a {
							@extend .btn-back;
						}

					}
				}

				.content {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(md) {
						@include make-col(3);
					}

					@extend .mb-4;
					@extend .pl-lg-5;

					.gallery {
						.gallery-img {
							max-width: 100%;
						}
					}
				}
			}
		}
	}
}
