// Text groups
/*

Type of text
text-type-body 					= Light 14 punt / regelafstand 22 punt / #4a4a49 en footer #001031
text-type-menu 					= Regular 14 punt KAPITAAL  / #001031
text-type-menu-sub				= Light 16 punt KAPITAAL / regelafstand 30 punt / #cf003d
text-type-title-h1 				= Bold 35 punt / #cf003d
text-type-title-h2 				= Light 35 punt / #cf003d
text-type-submenu-active		= Bold 16 punt / regelafstand 22 punt
text-type-submenu				= Regular 16 punt / #001031
text-type-bold		 			= Regular 16 punt / regelafstand 22 punt / #001031
text-type-intro-big 			= Light 24 punt / regelafstand 33 punt / #001031
text-type-footer-title			= Bold 16 punt KAPITAAL / regelafstand 30 punt / wit
text-type-select-active			= Bold 14 punt / #cf003d
text-type-blue-cap				= Regular 16 punt KAPITAAL / regelafstand 30 punt / #001031
text-type-circle-title			= Bold 40 punt KAPITAAL / wit
text-type-circle-text			= Regular 16 punt / regelafstand 20 punt ? #001031

*/

.text-type-body {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.375rem;
    color: $gray;
}
.text-type-menu {
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: uppercase;
    color: $dark-blue;
}
.text-type-menu-sub {
    font-weight: 300;
    line-height: 1.875rem;
    text-transform: uppercase;
    color: $red;
}
.text-type-title {
    font-weight: 600;
    line-height: 2.1875rem;
    color: $red;
}
.text-type-subtitle {
    @extend .text-type-title;
    font-weight: 400;
}
.text-type-submenu {
    color: $dark-blue;
}
.text-type-submenu-active {
    font-weight: 600;
    line-height: 1.375rem;
}
.text-type-bold {
    font-weight: 600;
    line-height: 1.375rem;
    color: $dark-blue;
}
.text-type-intro-big {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.0625rem;
    color: $dark-blue;
}
.text-card-title-red {
    position: relative;
    padding-left: 20px;
    margin: 0 0 4px;
    color: $red;
    font-size: 1rem;
    font-weight: 600;
    
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        font-family: "Font Awesome 5 Pro";
        content: "\F061";
    }
}

// Text
.text-type-regular-blue {
    @extend .mb-0;

    font-size: 1rem;
    line-height: 1.375rem;
    color: $dark-blue;
}
.text-type-light-gray {
    font-size: 1rem;
    line-height: 1.375rem;
    color: $gray;
}

// Footer
.text-type-footer {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.375rem;
    color: $dark-blue;
}
.text-type-footer-title {
    font-size: 1.06rem;  
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.875rem;
    color: $white;
}

.text-type-select-active {
    font-size: 0.875rem;
    font-weight: 600;
    color: $red;
}
.text-type-blue-cap {
    @extend .text-uppercase;
    font-size: 1rem;
    color: $dark-blue;
    line-height: 1.875rem;
}

// Circle
.text-type-circle-title {
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $white;
}
.text-type-circle-subtitle {
    font-size: 1rem;
    color: $dark-blue;
    font-weight: 500;
}
