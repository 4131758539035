main {
  padding: 80px 0 75px;
}
main h1 {
  @extend .text-type-subtitle;
  @extend .mb-4;
}
main h3 {
  @extend .text-type-intro-big;
}
body {
  b, strong {
    font-weight: 600;
    color: $dark-blue;
  }
}
.main {
  .container {
    .container-holder {
      .column img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}