.default {
    &.vacancy_vacancy_overview {
      .section_one {
        .column.one {
          @include media-breakpoint-up(md) {
            @include make-col(6);
          }
          @include media-breakpoint-up(lg) {
              @include make-col(8);
          }
          @include media-breakpoint-up(xl) {
              @include make-col(9);
          }

          @extend .mb-3;
        }
  
        .column.two {
          @include media-breakpoint-up(md) {
            @include make-col(6);
          }
          @include media-breakpoint-up(lg) {
              @include make-col(4);
          }
          @include media-breakpoint-up(xl) {
              @include make-col(3);
          }
  
          @extend .mb-3;
          @extend .pt-0;
        }
      }

      .section_two {
        .vacancy_overview {
          .item {
            @include make-col-ready();
            @include make-col(12);

            .card {
              @extend .border-0;
      
              .card-body {
                @extend .px-0;
                @extend .py-1;
      
                .card-caption {
                  .card-title {
                    @extend .text-card-title-red;
                    line-height: 1rem;
                    .service {
                        color: $dark-blue;
                    }
                    &:hover {
                        color: $dark-blue;
                    }
                  }
                  .card-subtitle {
                    @extend .text-type-bold;
                    font-size: 0.875rem;
                    margin: 0;
                  }
  
                  .card-text {
                    @extend .text-type-light-gray;
                    font-size: 0.875rem;
                  }
  
                  .card-btn {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.vacancy_vacancy_detail {
        .container {

            .info {
                h1 {
                    margin-bottom: 0;
                }
                h2 {
                    @extend .text-type-regular-blue;
                    @extend .mb-3;
                }

            }

            .back-to-overview {
                @extend .mt-5;
                a {
                    @extend .btn-back;
                }
            }
        }

    }
}