.default {
	&.home {
		height: 100%;
		&.second_visit {

			.main {
				min-height: 900px;
				@media (max-width: 767px) {
					min-height: auto;
				}
			}

			.section_one {
				.container-default {
					.container-holder {
						.circle-divisions {
							height: 650px;
							@media (max-width: 767px) {
								height: 250px;
							}
						}
					}
				}
			}
			.section_two {
				display: none;
			}
		}

		.main {
			min-height: 1400px;
			height: calc(100vh - 120px);
			@media (max-width: 767px) {
				height: calc(100vh - 80px);
				min-height: auto;
			}
			padding-top: 120px;
			background: transparent url("~project/public/images/background.jpg") center center no-repeat;
			background-size: cover;
		}

		.header {
			background: $white;
		}

		.back-to-home {
			display: none;
		}


		.section_one {
			@extend .px-3;
			@extend .pt-3;
			@extend .pb-3;

			@extend .px-sm-5;
			@extend .pt-sm-5;

			@extend .text-center;

			.container-default {

				padding-top: 5vh;

				@include media-breakpoint-down(md) {
					padding-top: 14vh;
				}

				.container-holder {



					&::before{
						display: none;
					}

/*  ==========================================================================
    home
    ========================================================================== */

	.circle-divisions {
		height: 750px;
		padding: 10vh 0 0;
		isolation: isolate;
		@media (max-width: 767px) {
			height: 350px;
		}
	}
	.circle-divisions .col {
		display: flex;
		justify-content: center;
		align-items: center;
		isolation: isolate;
	}

	.pulsating-circle {
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -185px;
		width: 370px;
		height: 370px;
		mix-blend-mode: multiply;

		display: block;
		pointer-events: all;
		cursor: pointer;
	}



	.pulsating-circle span {
		color: #fff;
		text-align: center;
		z-index: 99999;
		margin-top: -33px;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		isolation: isolate;
		@extend .text-type-circle-title;
	}
	.pulsating-circle:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		border-radius: 300px;
	}
	.pulsating-circle:hover:before,
	.pulsating-circle.active:before {
		content: '';
		position: relative;
		display: block;
		width: 150%;
		height: 150%;
		box-sizing: border-box;
		margin-left: -25%;
		margin-top: -25%;
		border-radius: 400px;
	}
	.pulsating-circle:hover:before {
		animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	}

	/* divisions */
	.pulsating-circle.division_blood {
		animation: pulse-dot 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.2s infinite;
	}
	.pulsating-circle.division_heart {
		margin-top: 275px;
		margin-left: -345px;
		animation: pulse-dot 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.8s infinite;
	}
	.pulsating-circle.division_vessels {
		margin-top: 275px;
		margin-left: -25px;
		animation: pulse-dot 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) -1.4s infinite;
	}
	.pulsating-circle.division_blood:after 	{ background-color: #e9242d;	}
	.pulsating-circle.division_heart:after 	{ background-color: #912e7a;	}
	.pulsating-circle.division_vessels:after { background-color: #00afec;	}

	.pulsating-circle:hover:before
	{
		animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	}
	.pulsating-circle.division_blood:hover:before,
	.pulsating-circle.division_blood.active:before 	{	background-color: #c21c24;	}
	.pulsating-circle.division_heart:hover:before,
	.pulsating-circle.division_heart.active:before	{	background-color: #6b215a;	}
	.pulsating-circle.division_vessels:hover:before,
	.pulsating-circle.division_vessels.active:before	{	background-color: #0178a7;	}

	.pulsating-circle.active {
		mix-blend-mode: unset;
		z-index: 99;
	}
	.pulsating-circle.active:before {
		animation: pulse-ring-active 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-duration: 0.5s;
		opacity: 0.9;
	}

	.pulsating-circle .division-block {
		opacity: 0;
		height: 0;
		transition: all .5s ease;
		transition-delay: 0.2s;

		z-index: 10;

		position: absolute;
		top: -50px;
		bottom: -50px;
		left: 0;
		right: 0;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;

	}
	.pulsating-circle.active .division-block {
		opacity: 1;
		height: auto;

	}
	.pulsating-circle .inner-circle {
		width: 180px;
		height: 180px;
		padding: 10px;
		border-radius: 180px;
		background: #fff;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		flex-direction: column;
		transition: all 0.5s ease;

		@extend .text-type-circle-subtitle;

		.arrow {
			margin-top: 5px;
			font-size: 20px;
			width: 30px;
			height: 30px;
			border-radius: 30px;
			background: $blue-vessels;
			margin: 0 auto;
			i {
				color: $white;
			}

		}
		&:hover {
			background: $dark-blue;
			color: $white;
		}

	}

@media (max-width: 767px) {

	.circle-divisions {
		padding: 100px 0 0;
	}

	.pulsating-circle {
		margin-left: -75px;
		width: 150px;
		height: 150px;
	}
	.pulsating-circle .division-block {
		top: -80px;
    	bottom: -80px;
	}
	.pulsating-circle .inner-circle {
		width: 130px;
		height: 130px;
		border-radius: 130px;
		font-size: 11px;
		padding: 15px;
	}
	.pulsating-circle span {
		margin-top: -18px;
		font-size: 22px;
	}
	.pulsating-circle:after {
		border-radius: 150px;
	}
	.pulsating-circle:hover:before,
	.pulsating-circle.active:before {
		width: 150%;
		height: 150%;
		margin-left: -25%;
		margin-top: -25%;
		border-radius: 200px;
	}
	.pulsating-circle.division_heart {
		margin-top: 115px;
		margin-left: -135px;
	}
	.pulsating-circle.division_vessels {
		margin-top: 115px;
		margin-left: -15px;
	}
	.arrow {
		font-size: 15px;
		width: 20px;
		height: 20px;

	}


}
	@keyframes pulse-ring {
		0% {
		  transform: scale(.33);
		}
		50% {
		  opacity: 0.5;
		}
		100% {
			opacity: 0;
		}
	}

	@keyframes pulse-ring-active {
		0% {
		  transform: scale(.33);
		}
		50% {
		  opacity: 0.5;
		}
		100% {
			opacity: 0.9;
		}
	  }

	@keyframes pulse-dot {
		0% {
		  transform: scale(.95);
		}
		50% {
		  transform: scale(1);
		}
		100% {
		  transform: scale(.95);
		}
	}
				}

			}
		}

		.section_two {
			.container-two-columns.container {
				background: #fff;
				padding: 30px;
			}


		}

	}
}
