.default {
  &.program_publications {
    .section_one {
      .column.one {
        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }
        @include media-breakpoint-up(xl) {
            @include make-col(9);
        }

        @extend .mb-3;
      }

      .column.two {
        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
        @include media-breakpoint-up(xl) {
            @include make-col(3);
        }

        @extend .mb-3;
        @extend .pt-0;
      }
    }
  }
}