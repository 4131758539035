section.navigation {

	position: relative;
	z-index: 999;
	margin: 40px 0 0;

	.container-fluid {
		overflow: hidden;
	}

	.navigation-block {
		@extend .container;
		margin: 10px auto;
		padding: 5px 15px 0;

			ul.navbar-nav {
				position: relative;
				display: block;
				width: 100%;
				height: auto;
				padding: 10px 0;

			&.level-0 {

				&::after,
				&::before {
					display: none;
				}
				li {
					@extend h1;
					a {
						pointer-events: none;
						cursor: default;
					}
				}
			}
			&::after,
			&::before {
				
				content: '';
				top: 0;
				left: -100%;
				right: -100%;
				position: absolute;
				border-top: 2px solid #7f8797;
			}
			&::before {
				
				top: inherit;
				bottom: -2px;

			}


			li {
				display: inline-block;
				margin-right: 15px;

				a {
					color: $dark-blue;
					&:hover {
						color: $red;
					}
					
				}

				&.active {
					font-weight: bold;
					a {
						color: $red;
					}
				}
			}
		}
	}

}
