.pager {
    .pagination {
        .page-item {
            margin: 0 5px;
            text-align: center;
            width: 38px;
            .page-link {
                border-color: $dark-blue;
                border-radius: 0;
                color: $dark-blue;
                font-weight: 600;
                height: 38px;
                overflow: hidden;
                transition: $transition-base;
                &:hover {
                    background: $dark-blue;
                    color: $white;
                }
            }
            &.active {

                .page-link {
                    color: $white;
                    border-color: $red;
                    &:hover {
                        background: $red;
                    }
                }
            }
            &.prev, &.next  {
                .page-link {
                    color: $white;

                    &::before {

                        font-family: "Font Awesome 5 Pro";
                        color: $dark-blue;

                        content: "\f054";
                        font-weight: 300;

                        margin-left: 3px;

                    }
                }
                &:not(.disabled):hover {
                    .page-link {
                        color: $dark-blue;
                        &::before {
                            color: $white;
                        }
                    }
                }
            }
            &.prev  {
                .page-link {
                    &::before {

                        content: "\f053";


                    }
                }
            }
            &.disabled {
				display: none;

                .page-link {
                    &::before {
                        opacity: 0.5;
                    }
                }
            }

        }
    }

}
